import React, { useEffect, useState } from "react";
import { Carousel } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel';
import '../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
import '../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css';
import Accordion from 'react-bootstrap/Accordion';
import { publicRequest } from "../../request-method";
import { successToast } from "../../utils/toast";
import OwlCarouselStage from "../../component/OwlCarousel";
import { images } from "../../constants/images";
import $ from "jquery";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import timeAnimation from "../../constants/json/Time.json";
import { Helmet } from 'react-helmet';

const options = {
    margin: 0,
    responsiveClass: true,
    nav: false,
    autoplay: true,
    loop: true,
    dots: false,
    smartSpeed: 2000,
    autoplayTimeout: 4000,
    autoWidth: true,
    autoplayTimeout: 4000,
    autoWidth: true,
    responsive: {
        0: {
            items: 1,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoWidth: false,
            autoWidth: false,
        },
        400: {
            items: 1,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoWidth: false,
            autoWidth: false,
        },
        500: {
            autoWidth: false,
            autoWidth: false,
            items: 1,
        },
        700: {
            items: 1,
        },
        1000: {
            items: 1

        }
    },
};

const emailvalidation = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;




const WebLanding = () => {

    const [email, setEmail] = useState("");
    const [error, setError] = useState({
        email: "",
    });

    const handleShowMailerLiteForm = () => {
        if (window.ml) {
            // If ml function is defined, call it to show the form
            window.ml('show', 'YYRhJ1', true);
        } else {
            // If ml function is not defined, log an error
            console.error("MailerLite script not loaded.");
        }
    };

    useEffect(() => {
        //   <script id="CookieDeclaration" src="https://consent.cookiebot.com/a4c1c226-6021-4bd4-9e72-1e4aa3c705e7/cd.js" type="text/javascript" async></script>
        <script id="CookieDeclaration" src="https://consent.cookiebot.com/82f5ef89-276d-4bce-873d-602ddb54cfe5/cd.js" type="text/javascript" async></script>
        $('#show-more-content').hide();
        $('#show-more').click(function () {
            $('#show-more-content').show(300);
            $('#show-less').show();
            $('#show-more').hide();
        });

        $('#show-less').click(function () {
            $('#show-more-content').hide(150);
            $('#show-more').show();
            $(this).hide();
        });
    }, [])
    
    const handleSubmit = () => {

        if (email) {
            if (emailvalidation.test(email) === true) {
                publicRequest.post('/api/promo/createPromo', { email }).then(() => {
                    setEmail('')
                    successToast('Subscribed successfully')
                }).catch((err) => {
                    console.log(err)
                })
                setError({ ...error, email: "" })
            }
            else {
                setError({ ...error, email: "Please enter a valid e-mail address" })
            }
        }
        else {
            setError({ ...error, email: "Please enter email id" })
        }
    }

    return (
        <div className="main-wrapper">
            <div className="wrapper-main-banner1">
                <img src={images.smallBanner01Image} />
            </div>
            <div className="wrapper-main-sec">
                {/* <div className="overlay"></div> */}
                <div className="wrapper-content-bx">
                    <div className="content-bx">
                        <h1 className="heading64">
                            Unleash Your Potential with Personalized Growth Plans
                        </h1>
                        <div className="showdesktop">
                            <p>Transform your life with getBetter, the AI-driven self-improvement platform. Tailored action plans and personalized suggestions, guided by our motivational sidekick Gebby, help you achieve goals and improve habits effectively. Start your journey to a better self today!
                            </p>
                        </div>
                        {/* <div class="showmobile">
                            <p>The future of self improvement is getBetter. So do you want to make the best out of yourself? We accompany you and help you to achieve your goals.</p><div id="show-more"><a href="javascript:void(0)">Show More</a></div><div id="show-more-content"><p>Our AI-driven platform crafts action plans unique to you. getBetter adapts these plans with your feedback for a truly personalized journey. Improve your habits, achieve your goals, and live the life you aspire. As a motivation our sidekick Gebby will accompany you on your path to the person you want to be. Personalized suggestions and community challenges will guide you on your journey to your better self.</p></div><div id="show-less"><a href="javascript:void(0)">Show less</a></div></div> */}
                    </div>

                    <div className="common-browser-btn">
                        <Link className="social-btn">
                            <img src={images.appStoreImage} />
                        </Link>
                        <Link className="social-btn me-0">
                            <img src={images.googlePlayImage} />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="wrap-update-content">
                        <div className="update-content-text update-left-content">
                            <h1>Stay Ahead with getBetter</h1>

                            {/* <p>Join our community and be the first to know about the latest updates, exclusive insights, and release dates.
                                Enter your email to stay connected with the revolution of personal growth.</p> */}
                        </div>

                        <div className="update-content-text update-right-content">
                            {/* <input type="email" name="email" className="form-control" placeholder="Enter your email for updates" value={email} onChange={(e) => { setEmail(e.target.value); setError({ ...error, email: "" }) }} />
                            <span className="error-text">{error.email}</span> */}
                            {/* <button type="button" className="common-btn email-submit-btn" onClick={handleSubmit}>
                                Keep Me Informed
                            </button> */}
                                                        <p>Join our community and be the first to know about the latest updates, exclusive insights, and release dates.
                                Enter your email to stay connected with the revolution of personal growth.</p>

                            <button className="ml-onclick-form common-btn email-submit-btn" onClick={handleShowMailerLiteForm}>
                                Click here to show form
                            </button>
                        </div>
            </div>
            <div className="wrapper-features-sec">
                <div className='wrap-carousel-sec' >
                    <div className="row">
                        <div className="col-12">
                            <Carousel

                                class="carousel slide carousel-fade" data-ride="carousel"
                                autoplay={false}>

                                <Carousel.Item className="carousel-feature-discover-content">
                                    <Helmet>
                                        <meta name="description" content="Interactive assessment results screen from getBetter app displaying a user's life quality percentages in key areas: Physical Health, Psychological Well-Being, Living Environment, and Social Relationships. The color-coded, easy-to-read chart offers insights into personal development areas, guiding users towards targeted self-improvement strategies" />
                                    </Helmet>
                                    <img
                                        className="d-block w-100 fluid-img"
                                        src={images.carousel01Image}
                                        alt="First slide"
                                    />
                                    {/* <img
                                        className="d-block w-100 fluid-img02"
                                        src={images.carousel101Image}
                                        alt="First slide"
                                    /> */}
                                    <img className="d-block w-100 fluid-img02" src={images.carousel1001Image} />
                                    <Carousel.Caption>
                                        <div className="wrap-carousel-content-bx">
                                            <h3>Discover the Dimensions <br /> of Your Well-Being</h3>
                                            <p>Our comprehensive assessment provides a detailed analysis of your life's key areas, from physical health to social relationships. Understand where you stand and what areas to focus on for a balanced, fulfilling life.</p>
                                        </div>
                                    </Carousel.Caption>

                                </Carousel.Item>

                                <Carousel.Item className="carousel-feature-tailor-content">
                                    <Helmet>
                                        <meta name="description" content="User personalization screen from getBetter app, offering the ability to choose focus areas for self-improvement based on assessment results. Features include selection of sub-domains like Psychological Well-Being and Physical Health, with an option to input personal time availability for a customized action plan." />
                                    </Helmet>

                                    <img
                                        className="d-block w-100 fluid-img"
                                        src={images.carousel02Image}
                                        alt="Second slide"
                                    />
                                    {/* <img
                                        className="d-block w-100 fluid-img02"
                                        src={images.carousel102Image}
                                        alt=""
                                    /> */}
                                    <img className="d-block w-100 fluid-img02" src={images.carousel1002Image} />
                                    <Carousel.Caption>
                                        <div className="wrap-carousel-content-bx">
                                            <h3>Tailor Your Growth Path</h3>
                                            <p>We will give you suggestions, but feel free to select the areas you wish to enhance and set
                                                your schedule. Our AI will adapt the action plan to fit your life, not the other way around.</p>
                                        </div>
                                    </Carousel.Caption>

                                </Carousel.Item>

                                <Carousel.Item className="carousel-feature-journey-content">
                                    <Helmet>
                                        <meta name="description" content="The dynamic homescreen of the getBetter app presents a personalized daily wellness plan, adaptable to the user's preferred schedule. It allows filtering by morning, afternoon, evening, and weekly actions, with a user interface that reflects the time-based filter for a customized experience." />
                                    </Helmet>

                                    <img
                                        className="d-block w-100 fluid-img"
                                        src={images.carousel03Image}
                                        alt="First slide"
                                    />
                                    <img
                                        className="d-block w-100 fluid-img02"
                                        src={images.carousel1003Image}
                                        alt=""
                                    />
                                    <Carousel.Caption>
                                        <div className="wrap-carousel-content-bx">
                                            <h3>Navigate Your Daily Wellness Journey</h3>
                                            <p>Effortlessly view and manage your daily action plans in our dynamic homescreen. Filter by
                                                time of day or week to align with your lifestyle and stay ahead of your well-being goals.</p>
                                        </div>
                                    </Carousel.Caption>

                                </Carousel.Item>

                                <Carousel.Item className="carousel-feature-personalize-content">
                                    <Helmet>
                                        <meta name="description" content="Customization screen of getBetter app where users can personalize their sidekick using points earned from completing actions. The sidekick appears throughout the app, providing motivation and support. Customization options include outfits and accessories to reflect the user's personal style and achievements." />
                                    </Helmet>

                                    <img
                                        className="d-block w-100 fluid-img"
                                        src={images.carousel04Image}
                                        alt="Second slide"
                                    />
                                    <img
                                        className="d-block w-100 fluid-img02"
                                        src={images.carousel1004Image}
                                        alt=""
                                    />
                                    <Carousel.Caption>
                                        <div className="wrap-carousel-content-bx">
                                            <h3>Personalize Your Motivational Sidekick</h3>
                                            <p>Customize your sidekick with the rewards you earn by completing actions. Watch them evolve as you progress in your personal growth journey, offering encouragement every step of the way. </p>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>

                                <Carousel.Item className="carousel-feature-knowledge-content">
                                    <Helmet>
                                        <meta name="description" content="Knowledge hub screen of the getBetter app, showcasing personalized blog entries, interactive quizzes, and polls designed to enhance user knowledge on various sub-domains." />
                                    </Helmet>

                                    <img
                                        className="d-block w-100 fluid-img"
                                        src={images.carousel05Image}
                                        alt="First slide"
                                    />
                                    <img
                                        className="d-block w-100 fluid-img02"
                                        src={images.carousel1005Image}
                                        alt=""
                                    />
                                    <Carousel.Caption>
                                        <div className="wrap-carousel-content-bx">
                                            <h3>Push Your Journey with Helpfull Knowledge</h3>
                                            <p>Dive into a curated selection of articles tailored to strengthen your growth areas. Participate in quizzes and polls to deepen your understanding and earn points.</p>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>

                                <Carousel.Item className="carousel-feature-celebrate-content">
                                    <Helmet>
                                        <meta name="description" content="User's progress tracking screen in the getBetter app, illustrating a detailed performance chart with daily actions, task completion, and performance comparison to past periods. It's designed to motivate users by visualizing their personal growth and celebrating every achievement in their self-improvement journey." />
                                    </Helmet>

                                    <img
                                        className="d-block w-100 fluid-img"
                                        src={images.carousel06Image}
                                        alt="Second slide"
                                    />
                                    <img
                                        className="d-block w-100 fluid-img02"
                                        src={images.carousel1006Image}
                                        alt=""
                                    />
                                    <Carousel.Caption>
                                        <div className="wrap-carousel-content-bx">
                                            <h3>Track Your Success, Celebrate Every Milestone</h3>
                                            <p>See your achievements and monitor your improvement trends with our intuitive progress dashboard.</p>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>

                                <Carousel.Item className="carousel-feature-community-content">
                                    <Helmet>
                                        <meta name="description" content="Community Challenges screen in the getBetter app displays ongoing, upcoming, and completed challenges, inviting users to collaborate and earn points as a group. This feature emphasizes community spirit, shared efforts, and the joy of reaching milestones together, enhancing the sense of belonging and collective achievement in the personal growth journey" />
                                    </Helmet>

                                    <img
                                        className="d-block w-100 fluid-img"
                                        src={images.carousel07Image}
                                        alt="Second slide"
                                    />
                                    <img
                                        className="d-block w-100 fluid-img02"
                                        src={images.carousel1007Image}
                                        alt=""
                                    />
                                    <Carousel.Caption>
                                        <div className="wrap-carousel-content-bx">
                                            <h3>Join The Community, Achieve Together</h3>
                                            <p>Participate in community challenges, contribute to shared goals and experience the collective triumph of accomplishment.</p>
                                        </div>
                                    </Carousel.Caption>
                                </Carousel.Item>

                            </Carousel>
                        </div>
                    </div>
                </div>
                <div className="wrap-features-content">
                    <h1>Unlock Your Potential</h1>
                    <p>Explore a World of Features Tailored to Push Your Growth</p>
                </div>
                <div className="wrap-owl-carousel">
                    <OwlCarousel className="slider-items owl-carousel owl-theme" {...options}>
                        <div className="item">
                            <div className="carousel-cardbx">
                                <div className="card carousel-card fst bgcolor-light-yellow">
                                    <div className="user-profile">
                                        <img src={images.personPlanIcon} />
                                    </div>
                                    <p>Personalized Action Plans</p>
                                </div>
                                <div className="card carousel-card second bgcolor-light-ocean life-quality-card">
                                    <div className="user-profile">
                                        <img src={images.lifeQualityAssessment} />
                                    </div>
                                    <p>Life Quality Assessment</p>
                                </div>
                            </div>
                            <div className="carousel-cardbx">
                                <div className="card carousel-card third bgcolor-yellow">
                                    <div className="user-profile">
                                        <img src={images.motivationalSidekickIcon} />
                                    </div>
                                    <p>Motivational Sidekick</p>
                                </div>
                                <div className="card carousel-card fourth bgcolor-sky">
                                    <div className="user-profile">
                                        <img src={images.adaptiveLearningIcon} />
                                    </div>
                                    <p>Adaptive Learning</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="carousel-cardbx">
                                <div className="card carousel-card fifth bgcolor-grass">
                                    <div className="user-profile">
                                        {/* <img src={images.userImage} /> */}

                                        <Lottie animationData={timeAnimation} loop={true} />
                                    </div>
                                    <p>Time-flexible Tasks</p>
                                </div>
                                <div className="card carousel-card sixth bgcolor-onion">
                                    <div className="user-profile">
                                        <img src={images.communityChallengesIcon} />
                                    </div>
                                    <p>Community Challenges</p>
                                </div>
                            </div>
                            <div className="carousel-cardbx">
                                <div className="card carousel-card seventh bgcolor-pink">
                                    <div className="user-profile">
                                        <img src={images.fullControlIcon} />
                                    </div>
                                    <p>Full Control Over Notifications</p>
                                </div>
                                <div className="card carousel-card eighth bgcolor-cream">
                                    <div className="user-profile">
                                        <img src={images.progressTrackIcon} />
                                    </div>
                                    <p>Progress Tracking</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="carousel-cardbx">
                                <div className="card carousel-card ninth bgcolor-paleturquoise">
                                    <div className="user-profile">
                                        <img src={images.educationIcon} />
                                    </div>
                                    <p>Educational Insights</p>
                                </div>
                                <div className="card carousel-card tenth bgcolor-reward">
                                    <div className="user-profile">
                                        <img src={images.rewardIcon} />
                                    </div>
                                    <p>Reward System</p>
                                </div>
                            </div>
                            <div className="carousel-cardbx">
                                <div className="card carousel-card eleventh bgcolor-olivegreen">
                                    <div className="user-profile">
                                        <img src={images.diverseLibraryIcon} />
                                    </div>
                                    <p>Diverse Action Library</p>
                                </div>
                                <div className="card carousel-card twelth bgcolor-skyblue">
                                    <div className="user-profile">
                                        <img src={images.privacyIcon} />
                                    </div>
                                    <p>Privacy First Approach</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="carousel-cardbx">
                                <div className="card carousel-card fst bgcolor-peach">
                                    <div className="user-profile">
                                        <img src={images.multiLanguageIcon} />
                                    </div>
                                    <p>Multi-language Support</p>
                                </div>
                                <div className="card carousel-card second bgcolor-interactiveCream">
                                    <div className="user-profile">
                                        <img src={images.freeTrailIcon} />
                                    </div>
                                    <p>7-Day Free Trial  </p>
                                </div>
                            </div>
                            <div className="carousel-cardbx">
                                <div className="card carousel-card third bgcolor-blossomWhite">
                                    <div className="user-profile">
                                        <img src={images.feedbackIcon} />
                                    </div>
                                    <p>Feedback Oriented Adaption  </p>
                                </div>
                                <div className="card carousel-card fourth bgcolor-firstSight">
                                    <div className="user-profile">
                                        <img src={images.sidekickIcon} />
                                    </div>
                                    <p>Sidekick Skins</p>
                                </div>
                            </div>
                        </div>


                        <div className="item">
                            <div className="carousel-cardbx">
                                <div className="card carousel-card fst bgcolor-springLily">
                                    <div className="user-profile">
                                        <img src={images.vacationModeIcon} />
                                    </div>
                                    <p>Vacation Mode</p>
                                </div>
                                <div className="card carousel-card second bgcolor-creamyIvory">
                                    <div className="user-profile">
                                        <img src={images.androidIcon} />
                                    </div>
                                    <p>Android & iOS</p>
                                </div>
                            </div>
                            <div className="carousel-cardbx">
                                <div className="card carousel-card third bgcolor-greatJoy">
                                    <div className="user-profile">
                                        <img src={images.homescreenIcon} />
                                    </div>
                                    <p>Dynamic Homescreen</p>
                                </div>
                                <div className="card carousel-card fourth bgcolor-lightpink">
                                    <div className="user-profile">
                                        <img src={images.nestedTimeIcon} />
                                    </div>
                                    <p>Up To 10 Nested Timers</p>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="carousel-cardbx">
                                <div className="card carousel-card fst bgcolor-coldTurquoise">
                                    <div className="user-profile">
                                        <img src={images.journalFunctionalityIcon} />
                                    </div>
                                    <p>Journal Functionality</p>
                                </div>
                                <div className="card carousel-card second bgcolor-fairAqua">
                                    <div className="user-profile">
                                        <img src={images.counterFunctionalityIcon} />
                                    </div>
                                    <p>Counter Functionality</p>
                                </div>
                            </div>
                            <div className="carousel-cardbx">
                                <div className="card carousel-card third bgcolor-frostyMint">
                                    <div className="user-profile">
                                        <img src={images.quizzesPollsIcon} />
                                    </div>
                                    <p>Quizzes and Polls</p>
                                </div>
                                <div className="card carousel-card fourth bgcolor-paleturquoise">
                                    <div className="user-profile">
                                        <img src={images.educationIcon} />
                                    </div>
                                    <p>Educational Insights</p>
                                </div>
                            </div>
                        </div>

                    </OwlCarousel>
                    {/* <OwlCarouselStage /> */}
                    {/* <OwlCarousel className="slider-items owl-carousel owl-theme" {...options}>
                        <div className="item">
                            <div className="card carousel-card">
                                <div className="user-profile">
                                    <img src={images.userImage} />
                                </div>
                                <p>Vacation Mode</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card carousel-card">
                                <div className="user-profile">
                                    <img src={images.userImage} />
                                </div>
                                <p>Android & iOS</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card carousel-card">
                                <div className="user-profile">
                                    <img src={images.userImage} />
                                </div>
                                <p>Dynamic Homescreen</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card carousel-card">
                                <div className="user-profile">
                                    <img src={images.userImage} />
                                </div>
                                <p>Up To 10 Nested Timers</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card carousel-card">
                                <div className="user-profile">
                                    <img src={images.userImage} />
                                </div>
                                <p>Journal Functionality</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card carousel-card">
                                <div className="user-profile">
                                    <img src={images.userImage} />
                                </div>
                                <p>Counter Functionality</p>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card carousel-card">
                                <div className="user-profile">
                                    <img src={images.userImage} />
                                </div>
                                <p>Quizzes and Polls</p>
                            </div>
                        </div>

                    </OwlCarousel> */}
                </div>

            </div>
            <div className="wrapper-addotion-sec">
                <h1>Frequently Asked Question</h1>
                <Accordion className="wrap-accordation-content">
                    <ul>
                        <li>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header> What is getBetter, and how can it help me improve my life quality?</Accordion.Header>
                                <Accordion.Body>
                                    getBetter is a personalized self-improvement app designed to empower individuals to enhance their life quality across various aspects, including physical health, mental and emotional wellness, living environment, and social well-being. By providing tailored action plans based on your unique life circumstances and preferences, getBetter guides you through actionable steps, offers motivational support, and adapts to your feedback to ensure you're always on the path to a more fulfilling life.                                </Accordion.Body>
                            </Accordion.Item>
                        </li>
                        <li>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header> How does getBetter customize my action plan?</Accordion.Header>
                                <Accordion.Body>
                                    getBetter uses an innovative AI-driven approach to understand your current life quality across different domains through a comprehensive assessment. Based on your responses, the app identifies your weakest areas and generates a personalized action plan. Every 20 days, you'll have the opportunity to provide feedback, allowing the app to refine and adjust your plan to better suit your evolving needs and preferences.                                </Accordion.Body>
                            </Accordion.Item>
                        </li>
                        <li>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Is getBetter suitable for someone with a busy schedule?</Accordion.Header>
                                <Accordion.Body>
                                    Absolutely! getBetter is designed with flexibility in mind, accommodating users with varying schedules. You can specify your time preferences for daily and weekly actions, ensuring the tasks seamlessly integrate into your routine without overwhelming you. The app respects your time and aims to make self-improvement accessible and manageable for everyone, regardless of how busy you may be.                                </Accordion.Body>
                            </Accordion.Item>
                        </li>
                        <li>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Can I try getBetter before subscribing?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, we offer a 7-day free trial for new users to explore the full functionality of the app. This trial period allows you to experience the personalized action plans, motivational sidekick, community challenges, and other features without any commitment. After the trial, you can choose to subscribe to continue accessing all premium features or use the application as a free user with full access to our (or your own created) actions to plan them on your own into your daily schedule.                                </Accordion.Body>
                            </Accordion.Item>
                        </li>
                        <li>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>How does getBetter ensure my data privacy and security?</Accordion.Header>
                                <Accordion.Body>
                                    Data privacy and security are our top priorities. getBetter uses advanced encryption and security measures to protect your personal information and assessment responses. We comply with all relevant data protection regulations in Europe and the US, ensuring your data is handled with the utmost care and confidentiality. You have full control over your data, with the ability to modify or delete it at any time.                                </Accordion.Body>
                            </Accordion.Item>
                        </li>
                        <li>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>What makes getBetter different from other habit-tracking or self-improvement apps?</Accordion.Header>
                                <Accordion.Body>
                                    Unlike traditional habit-tracking apps that offer generic solutions, getBetter provides a deeply personalized and adaptive experience. With our unique sidekick feature for motivation, community challenges for engagement, and a points system for unlocking skins, getBetter goes beyond tracking to truly empower you in your self-improvement journey. Our commitment to personalization, adaptability, and user engagement sets us apart, making self-improvement an enjoyable and fulfilling experience.                                </Accordion.Body>
                            </Accordion.Item>
                        </li>
                        <li>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>When will getBetter be available, and in which regions will it launch?</Accordion.Header>
                                <Accordion.Body>
                                    getBetter is set to launch in mid of Q2 2024, making its debut across Europe and the United States. Our aim is to make the app accessible to a wide audience from day one, providing users in these regions with the tools they need to embark on their journey towards improved life quality. Stay tuned for more updates on our official launch date and future plans for expansion to other regions.                                </Accordion.Body>
                            </Accordion.Item>
                        </li>
                    </ul>
                </Accordion>
            </div>
            <div className="wrapper-main-sec wrapper-banner4-sec">
                {/* <div className="overlay"></div> */}
                <div className="wrapper-banner4-small-bx">
                    <img src={images.iphoneImage} />
                </div>
                <div className="wrapper-content-bx">
                    <div className="content-bx">
                        <h1 className="heading48">
                            Begin Your Transformation Today
                        </h1>
                        <p>Start the empowering journey with getBetter and discover the joy of tailored growth. Our innovative app responds to your evolving needs, ensuring each step you take is aligned with your personal motivations. </p>
                    </div>

                    <div className="common-browser-btn">
                        <Link className="social-btn">
                            <img src={images.appStoreImage} />
                        </Link>
                        <Link className="social-btn me-0">
                            <img src={images.googlePlayImage} />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default WebLanding;