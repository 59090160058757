import { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './styles/index.scss';
import Routing from './pages/routing/Routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import toast, { Toaster } from "react-hot-toast";
import 'jquery';
import { trackPageView } from './hepler/matamoserivce';
// import Cookiebot from 'path/to/cookiebot'; // replace 'path/to/cookiebot' with the actual path


function App() {

  // useEffect(() => {
  //   // var _mtm = window._mtm = window._mtm || [];
  //   // _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  //   // var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  //   // g.async=true; g.src='https://cdn.matomo.cloud/getbetter.matomo.cloud/container_pjp3B1Xm.js'; s.parentNode.insertBefore(g,s);
  //   var _mtm = window._mtm = window._mtm || [];
  //   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  //   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
  //   g.async=true; g.src='https://cdn.matomo.cloud/getbetter.matomo.cloud/container_PiEIcSIn.js'; s.parentNode.insertBefore(g,s);
  // }, []);


//   var waitForTrackerCount = 0;
//   function matomoWaitForTracker() {
//     if (typeof _paq === 'undefined' || typeof Cookiebot === 'undefined') {
//       if (waitForTrackerCount < 40) {
//         setTimeout(matomoWaitForTracker, 250);
//         waitForTrackerCount++;
//         return;
//       }
//     } else {
//       window.addEventListener('CookiebotOnAccept', function (e) {
//           consentSet();
//       });
//       window.addEventListener('CookiebotOnDecline', function (e) {
//           consentSet();
//       })
//     }
//   }
//   function consentSet() {
//     if (Cookiebot.consent.statistics) {
//       _paq.push(['setCookieConsentGiven']);
//       _paq.push(['setConsentGiven']);
//     } else {
//       _paq.push(['forgetCookieConsentGiven']);
//       _paq.push(['forgetConsentGiven']);
//     }
//   }
//   document.addEventListener('DOMContentLoaded', matomoWaitForTracker());
// },[])

// useEffect(()=>{
//   // <!-- Matomo -->
//     var _paq = window._paq = window._paq || [];
//     /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
//     _paq.push(['requireCookieConsent']);
//     _paq.push(['trackPageView']);
//     _paq.push(['enableLinkTracking']);
//     (function() {
//       var u="https://getbetter.matomo.cloud/";
//       _paq.push(['setTrackerUrl', u+'matomo.php']);
//       _paq.push(['setSiteId', '3']);
//       var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
//       g.async=true; g.src='https://cdn.matomo.cloud/getbetter.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
//     })();
//   // <!-- End Matomo Code -->
  
// },[])


  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <Routing
      />
    </>
  );
}

export default App;






  // useEffect(() => {
  //   var _paq = window._paq || [];
  //   _paq.push(['requireCookieConsent']);
  //   _paq.push(['trackPageView']);
  //   _paq.push(['enableLinkTracking']);

  //   var waitForTrackerCount = 0;

  //   function matomoWaitForTracker() {
  //     if (typeof _paq === 'undefined' || typeof Cookiebot === 'undefined') {
  //       if (waitForTrackerCount < 40) {
  //         setTimeout(matomoWaitForTracker, 250);
  //         waitForTrackerCount++;
  //         return;
  //       }
  //     } else {
  //       window.addEventListener('CookiebotOnAccept', function (e) {
  //         consentSet();
  //       });
  //       window.addEventListener('CookiebotOnDecline', function (e) {
  //         consentSet();
  //       })
  //     }
  //   }

  //   function consentSet() {
  //     if (Cookiebot.consent.statistics) {
  //       _paq.push(['setCookieConsentGiven']);
  //       _paq.push(['setConsentGiven']);
  //     } else {
  //       _paq.push(['forgetCookieConsentGiven']);
  //       _paq.push(['forgetConsentGiven']);
  //     }
  //   }

  //   document.addEventListener('DOMContentLoaded', matomoWaitForTracker());
  // }, []);