import axios from "axios";

const BASE_URL = "https://getbetter.app/gbapi/";

export const publicRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: 'Basic R0VUQkVUVEVSOlFHMWtKRzRDIVFoUWlGd0RPcVN5SCE='
  },
});

