import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import { publicRequest } from "../request-method";
import { errorToast, successToast } from "../utils/toast";

const ContactUs = ({ show, handleClose }) => {

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
    });
    const [errors, setErrors] = useState({});
    const [captchaToken, setCaptchaToken] = useState("");

    
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let errorMessage = "";
    
        // Validation rules for first name and last name (no numbers or special characters)
        if (name === "firstName" || name === "lastName") {
            if (!/^[a-zA-Z\s]*$/.test(value)) {
                errorMessage = `${name.charAt(0).toUpperCase()}${name.slice(1).replace(/([A-Z])/g, ' $1')} should only contain letters`;
            }
        }
        if (name === "email") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(value)) {
                errorMessage = "Please enter a valid email address";
            }
        }
    
        // Validation rule for message (limit of 300 words)
        if (name === "message") {
            const wordCount = value.trim().split(/\s+/).length;
            if (wordCount > 300) {
                errorMessage = "Message should not exceed 300 words";
            }
        }
    
        // Clear the error message for the current input field if the value is not empty
        if (value.trim() !== "") {
            setErrors({ ...errors, [name]: errorMessage });
        } else {
            let customErrorMessage = "";
            switch (name) {
                case "firstName":
                    customErrorMessage = "First Name is required";
                    break;
                case "lastName":
                    customErrorMessage = "Last Name is required";
                    break;
                case "email":
                    customErrorMessage = "Email is required";
                    break;
                case "message":
                    customErrorMessage = "Message is required";
                    break;
                default:
                    customErrorMessage = "Field is required";
            }
            setErrors({ ...errors, [name]: customErrorMessage });
        }
    
        setFormData({ ...formData, [name]: value });
    };
    

    const handleSubmit = () => {
        // Validate form fields
        const errors = {};
        for (const key in formData) {
            if (!formData[key]) {
                errors[key] = `${key.charAt(0).toUpperCase()}${key.slice(1).replace(/([A-Z])/g, ' $1')} is required`;
            }
        }
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;

        }

        // Verify reCAPTCHA
        if (!captchaToken) {
            errorToast("Please complete the reCAPTCHA");
            return;
        }

        // Submit form data
        publicRequest.post("/api/promo/createPromoContact", {
            ...formData,
            captchaToken,
        })
            .then(() => {
                successToast("Form submitted successfully");
                setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    message: "",
                });
                setCaptchaToken("");
                handleClose();
            })
            .catch(error => {
                console.error("Error submitting form:", error);
                errorToast("Error submitting form. Please try again later.");
            });
    };


    return (
        <>
            <div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    className="wrap-contact-modal"
                    centered
                    size={'md'}>
                    <Modal.Header closeButton>
                        <Modal.Title className="form-title">Contact Us</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="wrap-form">
                            <div className="wrap-input-bx">
                                <label>First Name</label>
                                <input type="text" className="form-control" name="firstName" value={formData.firstName} onChange={handleInputChange} placeholder="Enter first name" />
                                {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                            </div>
                            <div className="wrap-input-bx">
                                <label>Last Name</label>
                                <input type="text" className="form-control" name="lastName" value={formData.lastName} onChange={handleInputChange} placeholder="Enter last name" />
                                {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                            </div>
                            <div className="wrap-input-bx">
                                <label>Email</label>
                                <input type="email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} placeholder="Enter email" />
                                {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                            <div className="wrap-input-bx">
                                <label>How can we help you?</label>
                                <textarea type="text" rows={4} className="form-control" name="message" value={formData.message} onChange={handleInputChange} placeholder="Type your message"></textarea>
                                {errors.message && <div className="text-danger">{errors.message}</div>}
                            </div>
                            <div className="mt-3 text-center">
                                <ReCAPTCHA
                                    sitekey="6LfrwMUpAAAAABYfVHgZC17POiPK_cZg7xFGh0Oq"
                                    onChange={(token) => setCaptchaToken(token)}
                                    className="mb-3"
                                />
                                <button type="button" className="common-btn" onClick={handleSubmit}>Get in touch</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}
export default ContactUs