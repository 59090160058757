import React from "react";

const Impressum = () => {
    return (
        <>
            <div className="wrapper-document">
                <div className="privacy-top-heading">
                    <h1>Impressum</h1>
                </div>
                <h6 className="privacy-status-date">Angaben gem. § 5 TMG (Telemediengesetz)</h6>
                <h6 className="heading16">Kontakt</h6>

                <div className="contact-content">
                    <h6>getBetter Lifestyle UG (haftungsbeschränkt)</h6>
                    <p>Leopoldstr. 2-8</p>
                    <p>Gebäude P-Block B-Nr. 121</p>
                    <p> 32051 Herford</p>
                    <p>info@getbetter.app </p>
                </div>
                <p>Vertreten durch: Geschäftsführer <b>Cagatay Ünlü</b></p>
                <p>Eingetragen im Handelsregister des Registergerichts in <b>Bad Oeynhausen</b> unter der Registernummer: HRB <b>19492</b></p>
                <p>E-Mail: <b>info@getbetter.app</b> </p>
                <p>Tel: +49 (0) <b>155 66151563</b></p>
                <p>Wir sind rund um die Uhr per E-Mail zu erreichen. Telefonisch erreichen Sie uns werktags von 09:00 - 14:00 Uhr</p>

                <h6 className="heading16">Wir bitten darum, uns keine werblichen Materialien oder Zeitschriften zuzusenden.</h6>
                <p className="mb-0">Hinweis zu § 36 VSBG: Wir nehmen an einem Streitbeilegungsverfahren vor einer</p>
                <p>Verbraucherschlichtungsstelle nicht teil und sind dazu auch nicht verpflichtet.</p>
                <p>Hinweis zur Plattform der EU-Kommission zur Online-Streitbeilegung <a href="#">http://www.ec.europa.eu/consumers/odr</a> (Art. 14 ODR-VO). Wir sind nicht bereit, an Streitbeilegungsverfahren vor einer Verbraucherstelle teilzunehmen.</p>


                <h6 className="heading16">Verantwortlicher im Sinne des § 18 Abs. 2 MStV</h6>

                <div className="contact-content">
                    <h6>getBetter Lifestyle UG (haftungsbeschränkt)</h6>
                    <p>Leopoldstr. 2-8</p>
                    <p>Gebäude P-Block B-Nr. 121</p>
                    <p> 32051 Herford</p>
                </div>

                <h6 className="heading16">Kommunikationsstelle für rechtswidrige Inhalte nach dem Digital Services Act </h6>
                <p>Die Meldung rechtswidriger Inhalte erfolgt gem. unserer „Regelungen zum Digital Services Act an</p>
                <p>E-Mail: <b>info@getbetter.app</b></p>

                <h6 className="heading16">Urheberrecht</h6>
                <p>Das Urheberrecht für die Inhalte auf unserer Website liegt, soweit nicht anderweitig gekennzeichnet bei dem Betreiber der Website. Die Inhalte unterliegen dem deutschen Urheberrecht. Jegliche Nutzung im Rahmen einer Vervielfältigung, Verbreitung, Verwertung etc. bedarf der vorherigen Zustimmung in Textform durch den Betreiber der Website. Dies gilt insbesondere für kommerzielle Verwertungen.</p>

            </div>
        </>
    )
}
export default Impressum;