export const images = {
    logoImage: process.env.PUBLIC_URL + "/assets/images/logo.png",
    userImage: process.env.PUBLIC_URL + "/assets/images/user-img.png",
    appStoreImage: process.env.PUBLIC_URL + "/assets/images/app-store.png",
    googlePlayImage: process.env.PUBLIC_URL + "/assets/images/google-play.png",
    carousel01Image: process.env.PUBLIC_URL + "/assets/images/carousel01.png",
    carousel101Image: process.env.PUBLIC_URL + "/assets/images/carousel101.png",
    carousel02Image: process.env.PUBLIC_URL + "/assets/images/carousel02.png",
    carousel102Image: process.env.PUBLIC_URL + "/assets/images/carousel102.png",
    carousel03Image: process.env.PUBLIC_URL + "/assets/images/carousel03.png",
    carousel04Image: process.env.PUBLIC_URL + "/assets/images/carousel04.png",
    carousel05Image: process.env.PUBLIC_URL + "/assets/images/carousel05.png",
    carousel06Image: process.env.PUBLIC_URL + "/assets/images/carousel06.png",
    carousel07Image: process.env.PUBLIC_URL + "/assets/images/carousel07.png",
    carousel1001Image: process.env.PUBLIC_URL + "/assets/images/carousel1001.png",
    carousel1002Image: process.env.PUBLIC_URL + "/assets/images/carousel1002.png",
    carousel1003Image: process.env.PUBLIC_URL + "/assets/images/carousel1003.png",
    carousel1004Image: process.env.PUBLIC_URL + "/assets/images/carousel1004.png",
    carousel1005Image: process.env.PUBLIC_URL + "/assets/images/carousel1005.png",
    carousel1006Image: process.env.PUBLIC_URL + "/assets/images/carousel1006.png",
    carousel1007Image: process.env.PUBLIC_URL + "/assets/images/carousel1007.png",
    smallBanner01Image: process.env.PUBLIC_URL + "/assets/images/smallBanner01.png",
    iphoneImage: process.env.PUBLIC_URL + "/assets/images/iphone.png",
    personPlanIcon: process.env.PUBLIC_URL + "/assets/images/personPlanIcon.png",
    lifeQualityAssessment: process.env.PUBLIC_URL + "/assets/images/lifeQualityAssessment.png",
    motivationalSidekickIcon: process.env.PUBLIC_URL + "/assets/images/motivationalSidekickIcon.png",
    adaptiveLearningIcon: process.env.PUBLIC_URL + "/assets/images/adaptiveLearningIcon.png",
    communityChallengesIcon: process.env.PUBLIC_URL + "/assets/images/communityChallengesIcon.png",
    fullControlIcon: process.env.PUBLIC_URL + "/assets/images/fullControlIcon.png",
    progressTrackIcon: process.env.PUBLIC_URL + "/assets/images/progressTrackIcon.png",
    educationIcon: process.env.PUBLIC_URL + "/assets/images/educationIcon.png",
    rewardIcon: process.env.PUBLIC_URL + "/assets/images/rewardIcon.png",
    diverseLibraryIcon: process.env.PUBLIC_URL + "/assets/images/diverseLibraryIcon.png",
    privacyIcon: process.env.PUBLIC_URL + "/assets/images/privacyIcon.png",
    homescreenIcon: process.env.PUBLIC_URL + "/assets/images/homescreenIcon.png",
    multiLanguageIcon: process.env.PUBLIC_URL + "/assets/images/multiLanguageIcon.png",
    freeTrailIcon: process.env.PUBLIC_URL + "/assets/images/freeTrailIcon.png",
    feedbackIcon: process.env.PUBLIC_URL + "/assets/images/feedbackIcon.png",
    sidekickIcon: process.env.PUBLIC_URL + "/assets/images/sidekickIcon.png",
    vacationModeIcon: process.env.PUBLIC_URL + "/assets/images/vacationModeIcon.png",
    nestedTimeIcon: process.env.PUBLIC_URL + "/assets/images/nestedTimeIcon.png",
    androidIcon: process.env.PUBLIC_URL + "/assets/images/androidIcon.png",
    journalFunctionalityIcon: process.env.PUBLIC_URL + "/assets/images/journalFunctionalityIcon.png",
    counterFunctionalityIcon: process.env.PUBLIC_URL + "/assets/images/counterFunctionalityIcon.png",
    quizzesPollsIcon: process.env.PUBLIC_URL + "/assets/images/quizzesPollsIcon.png",
}