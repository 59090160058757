import React from "react";


const TermCondition = () => {
    return (
        <>
            <div className="wrapper-document">
                <div className="privacy-top-heading mb-3">
                    <h3 className="text-center">Terms of use</h3>
                </div>
                <p className="text-center">the</p>

                <div className="contact-content text-center">
                    <h6>getBetter Lifestyle UG (haftungsbeschränkt) (limited liability)</h6>
                    <p>Leopoldstr. 2-8 </p>
                    <p>32051 Herford </p>
                </div>
                <h5 className="heading16 text-center"><span className="heading-w-dot text-underline">General regulations</span></h5>
                <p>Welcome to getBetter. With getBetter, we want to help you manage your daily tasks better and build positive habits. All services associated with getBetter and the getBetter app are governed by these Terms of Use.</p>

                <h6 className="heading16 text-center"><span className="heading-w-dot">General information and scope of application</span></h6>
                <ul>
                    <li>These Terms of Use ("<b>Terms of Use</b>") apply to the services provided by getBetter UG (haftungsbeschränkt) (hereinafter also referred to as "we" or "getBetter") to the user (hereinafter referred to as "User" or "you") in the context of the use of our getBetter App.</li>
                    <li>These Terms of Use apply regardless of whether you are acting as a consumer, entrepreneur or merchant. Some special features apply to consumers, which we may point out at the appropriate places in these Terms of Use. A consumer is any natural person who enters into a legal transaction for purposes that are predominantly outside their trade, business or profession (Section 13 BGB). An entrepreneur is a natural or legal person or a partnership with legal capacity who or which, when entering into a legal transaction, acts in exercise of his or its trade, business or profession (Section 14 BGB).</li>
                    <li>Your General Terms and Conditions, should you have any, shall not apply, regardless of whether you expressly refer to them or we do not expressly object to them. Your General Terms and Conditions shall only become part of this contract if we expressly agree to them. Individual agreements between us in offers, order confirmations, declarations of acceptance, etc. shall take precedence over the provisions of these GTC.</li>
                </ul>

                <h6 className="heading16 text-center"><span className="heading-w-dot">Subject matter of the contract</span></h6>
                <ul>
                    <li>With the getBetter app, we provide you with a platform to support the acquisition of positive habits (hereinafter also referred to as "<b>services</b>"). We only provide the technical basis for the use of our services and expressly do not guarantee or warrant any actual improvement in your habits through the use of the getBetter app.</li>
                    <li>The subject of these Terms of Use is the provision of the getBetter app free of charge and your option to use the getBetter app via a paid profile. </li>
                    <li>An overview of the entire service portfolio of our getBetter app can be found in the features that can be viewed and used in the getBetter app. </li>
                </ul>

                <h6 className="heading16 text-center"><span className="heading-w-dot">Use of our services & registration</span></h6>
                <ul>
                    <li>To access the getBetter app, you must first download and install the getBetter app from the Apple App Store or the Google Play Store (hereinafter collectively referred to as the "App Store"). This requires the existence or creation of a new account for the App Store. By downloading the getBetter app from the App Store, you receive a personal, limited, single, non-transferable license to install the getBetter app on a mobile device for the duration of the contract term. The license is valid for all mobile devices linked to the App Store account.</li>
                    <li>Your free registration as a user is required to use all of our services. Without registration, you will only have access to the Life Quality Assessment, which is stored locally on your device and will be lost if you delete the app.</li>
                    <li>Only natural persons with unlimited legal capacity may register as users. To register, the registration form provided in our app must be filled out completely and truthfully, stating a user name, and sent to us. Your user name must not infringe the rights of third parties or offend common decency. We are entitled to request evidence to verify the data provided.</li>
                    <li>By submitting the registration form, you submit a binding offer to us to conclude a contract of use for a paid premium version. The user contract is concluded when we confirm your registration and activate your user account. Each user may only register once.</li>
                    <li>To use our services, at least the following technical requirements are necessary:

                        <ul className="mb-3 mt-3">
                            <li>Internet connection</li>
                            <li>Current version of the app</li>
                        </ul>
                    </li>

                    <li>We have the right to block or exclude you from using our services and to delete your user account and all services booked by you if we have reasonable grounds to believe that you have violated or are violating material obligations under these Terms of Use or the law. In such a case, we will inform you of the planned exclusion by e-mail and give you the opportunity to comment. In any case, we will take your legitimate interests into account when deciding whether to block or exclude you and evaluate your statement accordingly. We will inform you of the result before blocking or excluding you. The right to extraordinary termination remains unaffected.</li>
                </ul>
                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Your right to use our services</span>
                </h6>
                <ul>
                    <li>You receive a simple, non-exclusive right to use our services, limited in time to the duration of the contract and unrestricted in terms of territory. </li>
                    <li>You are not entitled to exhibit, communicate to the public, in particular make available to the public, edit, redesign, translate, decompile or otherwise redesign our services. Your rights under §§ 69d para. 3, 69e UrhG remain unaffected.</li>
                    <li>The content of our services (in particular in our app and our websites) is our exclusive property or our exclusive rights holder or the property / rights holder of our service providers. All content is protected by national and international law, in particular copyright law. Unauthorized distribution, reproduction, exploitation or any other infringement of our industrial property rights and copyrights will be prosecuted under civil and/or criminal law.</li>
                </ul>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Availability</span>
                </h6>
                <p>For technical reasons, we cannot guarantee the permanent and complete availability of the servers on which our services are operated. The availability of our website or individual services may therefore be limited from time to time, in particular due to the need to carry out maintenance or repair work. </p>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Limitations of liability</span>
                </h6>
                <ul>
                    <li>Our liability arising from the contract concluded between us, irrespective of the legal grounds, for your damages that do not result from injury to life, body or health, is limited to the amount of damages typically foreseeable at the time of conclusion of the contract and, on the merits, to essential contractual obligations, insofar as the damage was not caused by us or by a legal representative or vicarious agent either intentionally or through gross negligence.</li>
                    <li>This limitation of liability does not apply if the damage is based on an intentional or grossly negligent breach of essential contractual obligations by us or by a legal representative or vicarious agent. A material contractual obligation is an obligation whose fulfillment is essential for the proper execution of the contract and on whose compliance you may regularly rely.</li>
                    <li>If claims are asserted against us by third parties due to actions carried out by you on our getBetter app and the resulting legal infringements, you are obliged to indemnify us against all related claims. To this end, you must reimburse us for the costs of a reasonable legal defense and other costs incurred as a result of the claim.</li>
                </ul>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Principles for the provision of our services</span>
                </h6>
                <ul>
                    <li>With our getBetter app and its functions, we merely provide you with a technical basis for the options available to you within the scope of the features of the getBetter app. We assume no responsibility for the actions carried out with our getBetter app or for the content processed with our getBetter app. </li>
                    <li>In principle, the provisions of tenancy law apply to the use of our services. Maintenance measures such as troubleshooting, updates or further developments are part of our service. Further support is offered by agreement. In addition to the maintenance measures, the statutory warranty for rental defects applies</li>
                    <li>Adjustments, changes and additions to our services as well as measures that serve to identify and rectify malfunctions will only lead to a temporary interruption or impairment of accessibility if this is absolutely necessary for technical reasons. </li>
                    <li>You may not transfer our services to third parties for commercial use</li>
                    <li>We reserve the right to change, adapt and increase or reduce the scope of functions of our services and the related documents and attachments, taking your interests into account, provided that we do not thereby breach our contractually assumed primary performance obligations towards you</li>
                    <li>We secure our systems against virus attacks. Nevertheless, virus attacks can never be completely ruled out. It is also possible that unauthorized third parties may send messages using our name without our consent that contain viruses or so-called spyware, for example, or link to web content that contains viruses or spyware. We have no influence over this. You should therefore check all incoming messages that are sent under our name. This also applies to any messages from other users. </li>
                    <li>We are not responsible for any damage or loss of data that may result from the installation of software that does not originate from us on your end devices.</li>
                    <li>You undertake not to use your end device or the app store or app account, login name or password of another user without authorization under any circumstances. If a third party uses one of the accounts after obtaining the access data because the user has not adequately secured it against unauthorized access, the user must be treated as if they had acted on their own. </li>
                    <li>In the event of reasonable suspicion that access data has become known to unauthorized third parties, we are entitled, but not obliged, for security reasons, to change the access data independently at our own discretion without prior notice or to block the use of the account. We will inform the authorized user of this immediately and, upon request, provide him/her with the new access data within a reasonable period of time. You have no right to demand that the original access data be restored.</li>
                    <li>You must inform us immediately as soon as you become aware that unauthorized third parties are aware of the access data to your App Store account or that unauthorized third parties are using your end device. We would like to point out that access data should be changed regularly for security reasons.</li>
                    <li>It is prohibited to use our services:

                        <ul>
                            <li>infringe third-party property rights such as trademarks, copyrights and naming rights, </li>
                            <li>harass other users and third parties, </li>
                            <li>Use any measures, mechanisms or software in connection with the getBetter app that may interfere with the function and operation of the app</li>
                            <li>to take measures that could result in an unreasonable or excessive load on the technical capacities of the getBetter app,</li>
                            <li>block, overwrite or modify content,</li>
                            <li>add elements to the app or change, delete or modify elements of the getBetter app in any other way,</li>
                            <li>copy, extract or otherwise use graphic elements or attempt to decompile the source code of the getBetter app (subject to § 69e UrhG),
                            </li>
                            <li>use tools that interfere with the operation of the getBetter app (in particular so-called "bots", "hacks" etc.),</li>
                            <li>to obtain premium functions or other advantages, such as the systematic or automatic control of the app or individual functions of the getBetter app, through the use of third-party software or other applications or to exploit program errors for your own benefit ("exploits"),</li>
                            <li>distribute commercial advertising for third-party products or programs in connection with our services,</li>
                            <li>to use documents, files, third-party IT systems and data containing malicious code or viruses in connection with our services, </li>
                            <li>use mechanisms, software and scripts that go beyond the functionalities and interfaces provided, in particular if this blocks, modifies, copies or overwrites our services, and </li>
                            <li>impair our services by altering data (§ 303a StGB), computer sabotage (§ 303b StGB), falsification of evidence (§ 269, 270 StGB), suppression of evidence (§ 274 StGB), computer fraud (§ 263a StGB), spying on data (§ 202a StGB), interception of data (§ 202b StGB) or other criminal offenses.</li>

                        </ul>
                    </li>

                </ul>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Contract duration & withdrawal/cancellation</span>
                </h6>
                <ul>
                    <li>The duration of the contract between us is based on the duration of the service you have booked. We generally offer monthly and annual terms. The specific terms and the respective contractual and pricing conditions can be found in the respective price model. </li>
                    <li>The existing contracts between us can be terminated with a notice period of 4 weeks. Cancellation must be made in text form (by email) to info@getbetter.app or by clicking on the "Cancel now" button in your user account. </li>
                    <li>The right to extraordinary termination for good cause (Section 314 BGB) remains unaffected. Good cause exists for us in particular if (i) you seriously violate these GTC, or (ii) you are in arrears with the payment of the costs for the use of chargeable services despite a reminder and a grace period for payment of at least two calendar weeks.</li>
                </ul>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Place of jurisdiction</span>
                </h6>
                <p>If you are acting as a company or are a merchant within the meaning of the German Commercial Code (HGB), our registered office is the place of jurisdiction for all disputes arising from this contract. In the case of contracts with consumers, the statutory provisions on the competent court shall apply.</p>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Online dispute resolution</span>
                </h6>
                <p>Information on online dispute resolution for consumers: We would like to draw your attention to the possibility of online dispute resolution (so-called "OS-getBetter App"). Consumers can use the OS-getBetter app as a contact point for the out-of-court settlement of disputes concerning contractual obligations arising from online purchase or service contracts. The OS-getBetter app can be accessed via the following link: <a href="#">http://ec.europa.eu/consumers/odr</a>. We are not prepared to participate in the settlement of disputes via this OS-getBetter app.</p>
                <p>Note on § 36 VSBG: We do not participate in a dispute settlement procedure before a consumer arbitration board and are not obliged to do so.</p>


                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Miscellaneous</span>
                </h6>
                <ul>
                    <li>Should any provision of these GTC be or become invalid or unenforceable, the remaining provisions of these GTC shall remain unaffected, unless the omission of individual clauses would place a contracting party at such an unreasonable disadvantage that it can no longer be reasonably expected to adhere to the contract.</li>
                    <li>Amendments and additions to the GTC and the entire contract existing between us must be made in text form to be effective. Amendments and additions to the contract that we (have to) make due to changed legal or technical requirements for the provision of our services and that do not have a negative impact on the services to which you are entitled shall become effective if you do not object to an amendment in text form within one month of receipt of a notification of amendment and we have informed you in advance of your right to object. If you object to the change, the contract will continue to apply unchanged and we are entitled to terminate the contract without notice with one month's notice to the end of the next calendar month. Amendments and additions to the contract that we wish to make due to changes in performance, remuneration or other commercial or operational requirements will only become effective if you expressly agree to them. This consent can be given by clicking on a consent button in the change notification (e-mail or pop-up when using our services) or in another simple and transparent way provided by us for you. The text form also applies to a change to this formal clause. The precedence of individual ancillary agreements remains unaffected. The aforementioned deadlines do not apply and there is only a right to information about changes to the contract if the changes are necessary to avert an unforeseen and imminent danger in order to protect you from fraud, malware, spam, data protection violations or other cyber security risks.</li>
                    <li>Both parties are entitled to transfer the contract to a company affiliated with them, a legal successor or a company taking over the respective services. They shall inform each other of this in text form prior to the planned transfer.</li>
                </ul>

                <h4 className="text-center mt-4 mb-4">
                    <span className="heading-w-dot text-underline">Regulations concerning services subject to remuneration</span>
                </h4>
                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Conclusion of a contract for the use of chargeable services</span>
                </h6>

                <ul className="mt-0">
                    <li>A contract for the use of chargeable services is concluded as follows (subject to Section 12.2 on the purchase of chargeable app and in-app purchases):
                        <ul>
                            <li>In our app you will find information about the content and costs of the fee-based services we offer. This information does not constitute an offer to conclude a contract for the use of our services.</li>
                            <li>Only by submitting the corresponding booking form, which is operated by so-called "Merchant of Records", i.e. payment service providers commissioned by us who take over the licensing of the getBetter App for us, do you submit a binding offer to us or the Merchant of Records to conclude a contract for the corresponding services. </li>
                            <li>The conclusion of the contract and the processing of payments are governed by the terms and conditions of the Merchant of Records.</li>
                            <li>The Merchant of Records then accepts or rejects your offer to conclude the contract in its own name or as our representative, messenger or other authorized agent. We ourselves have no influence on this, as the entire processing of the booking and payment flows is in the hands of the Merchant of Records. However, a contract for the use of the getBetter app is always concluded directly between you and us, unless otherwise stipulated in the Merchant of Records' terms of use. </li>
                            <li>Once you have paid in full for the services you have purchased, you will receive immediate access to the getBetter app.</li>
                        </ul>
                    </li>
                    <li>
                        App and in-app purchases are made as follows:
                        <ul className="mb-0 mt-0">
                            <li>The purchase and download of the paid version of the getBetter app can also be made in the app store (hereinafter referred to as "<b>app purchase</b>"). The processing of the App Purchase is carried out by the App Store. The conclusion of the contract and payment processing are governed by the terms and conditions of the App Store.</li>
                            <li>The purchase of premium functions may be possible directly in the app (hereinafter referred to as "in-app <b>purchase</b>"). The in-app purchase is processed by the app store. The conclusion of the contract and payment processing are governed by the terms and conditions of the app store.</li>
                            <li>The "purchase" of an app and/or the purchase of premium functions basically only means the granting of a right to use it. The scope and duration of the right of use granted depend on the respective service (see above for the right of use). </li>
                        </ul>
                    </li>
                </ul>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Remuneration & terms of payment</span>
                </h6>
                <ul>
                    <li>The type and amount of remuneration for the service you have booked will be communicated to you during the booking process, in the booking overview and in the booking confirmation and, unless expressly stated otherwise, is inclusive of statutory VAT.</li>
                    <li>During the booking process, you will be informed about the available payment methods and can select the payment method that suits you best. </li>
                    <li>If we integrate third-party providers to process the payment transaction, e.g. Merchat of Records, their terms and conditions also apply. </li>
                    <li>If you fail to meet a statutory or agreed payment deadline, you shall be in default immediately and we shall be entitled to charge interest on arrears accordingly. </li>
                </ul>

                <h5 className="text-center">
                    <span className="heading-w-dot text-underline">Regulations on the Digital Services Act</span>
                </h5>
                <p>We make the following regulations because we are obliged to do so under the "Digital Services Act", the EU Regulation "(EU) 2022/2065 on a single market for digital services of the European Union". </p>
                <p>The Digital Services Act aims to establish rules that create a safe, predictable and trustworthy environment for people using platforms and websites like ours. </p>
                <p>The Digital Services Act regulates in particular the handling of illegal content on our getBetter app and obliges us to take specific measures, which we describe in more detail in these "Regulations on the Digital Services Act".</p>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Moderation & justification of the deletion of illegal content</span>
                </h6>
                <ul>
                    <li>We delete, block or restrict illegal content on our getBetter app. "<b>Content</b>" is any information of any kind. This information is "<b>unlawful" if</b> it violates our terms and conditions, service descriptions, guidelines or applicable law.</li>
                    <li>Illegal content can be reported by users, customers, partners or third parties. For the process of such a reporting procedure, see para. 2 "<b>Reporting and redress procedure</b>".</li>
                    <li>Insofar as content reported or recognized by us is unlawful and we have deleted, blocked or restricted it, we will justify our decision to delete, block or restrict this content and any suspension or termination of the provision of all or part of our services to the person who published the unlawful content, unless it is misleading, extensive commercial content. In this statement of reasons, we are particularly notifying the following points:

                        <ul className="mb-3 mt-3">
                            <li>Whether our decision concerns the removal of the information, the blocking of access to the information, the downgrading of the information or the restriction of the display of the information or the suspension or termination of payments in connection with that information, or imposes other specified measures in connection with the information, and the territorial scope of the decision, if any, and the duration of its validity;</li>
                            <li>The facts and circumstances on which our decision is based, including, where appropriate, whether our decision was made as a result of a report made or as a result of voluntary investigation on our own initiative and, where strictly necessary, the identity of the person making the report;</li>
                            <li>Information on whether automated means have been used for decision-making, including information on whether our decision was made in relation to content recognized or detected by automated means;</li>
                            <li>If our decision concerns only "allegedly" unlawful content, a reference to the legal basis and an explanation of why the information is considered unlawful content on this basis; </li>
                            <li>If our decision is based on the alleged incompatibility of the information with our GTC, a reference to the relevant contractual provision and an explanation of why the information is considered incompatible with it;</li>
                            <li>Information on whether the data subject has legal remedies against the measure, in particular - depending on the circumstances - internal complaints management procedures, out-of-court dispute resolution and judicial remedies.</li>
                        </ul>
                    </li>

                    <li>If a criminal offense has been committed through the publication of illegal content, or if the commission of a criminal offense is suspected as a result, we will immediately inform the law enforcement or judicial authorities of our suspicions.</li>
                </ul>


                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Reporting and redress procedures</span>
                </h6>
                <ul>
                    <li>We review unlawful content as soon as we become aware of any suspicion of the publication of such unlawful content. We become aware of this either through our own review or by reporting the content in question to our central contact point (see section 3 below).</li>
                    <li>
                        Illegal content can be reported directly online. Reports must be sent exclusively to our central contact point (see section 3 below) and must contain at least the following information:

                        <ul className="mb-3 mt-3">
                            <li>A duly substantiated explanation of why the reporting person or organization considers the information in question to be illegal content;</li>
                            <li>Clear indication of the exact storage/finding location of the content, e.g. by means of a precise URL and other useful information for identifying the content, e.g. screenshots, comments, etc;</li>
                            <li>Name and e-mail address of the reporting person/entity, unless it concerns offenses related to sexual abuse, sexual exploitation, child pornography, contacting children for sexual purposes; including incitement and aiding and abetting or attempting to commit the aforementioned offenses (see EU Directive 2011/93/EU, Art. 3 to 7);</li>
                            <li>A statement that the reporting person or entity has a good faith belief that the information and statements contained in the report are accurate and complete.</li>

                        </ul>
                    </li>
                    <li>We will immediately send an electronic confirmation of receipt of the notification to the contact details provided.</li>
                    <li>We examine the reports received promptly, carefully, objectively and free of arbitrariness and decide whether the reported content is illegal without a detailed legal examination and act accordingly. If the reported content is unlawful, we will immediately delete, block or restrict it and, if necessary, take further measures mentioned in section 1 paragraph 3.</li>
                </ul>

                <h6 className="heading16 text-center">
                    <span className="heading-w-dot">Central contact point for communication</span>
                </h6>
                <p>We have set up a central contact point for communication. This is responsible for receiving electronic reports of suspected illegal content from reporting persons and for inquiries from authorities or third parties relating to the handling of illegal content. Please note the requirements in section 2 for the content of reports.</p>
                <p>All persons, authorities or other bodies (press, affected parties, etc.) can report illegal content directly online using our procedure at:</p>
                <p>info@getbetter.app</p>
                <p>We then take care of all notifications as part of the process mentioned in sections 1 to 3.</p>

                <h6 className="privacy-status-date">Status: March 2024</h6>
            </div>
        </>
    )
}
export default TermCondition;