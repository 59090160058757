import React from "react";

const AboutUs = () => {
    return (
        <>
            <div className="wrapper-document">
                <div className="privacy-top-heading">
                    <h3 className="text-center">
                        <span>About Us</span>
                        </h3>
                </div>
                <p>At getBetter, our mission is deeply personal. We saw the potential for a platform that could profoundly change how we approach personal growth, beyond the limits of traditional habit trackers. Our vision is to create a life improvement platform that not only helps users enhance their lives and achieve their goals but also brings together a community of like-minded individuals. This is about fostering a space where every user can find support, motivation, and inspiration as they navigate their journey towards holistic wellness. Together, we're building more than an app; we're cultivating a movement towards enriched living.</p>
                <p>Guided by the principle that feedback is the compass for progress, getBetter places immense value on user insights. This approach ensures that every feature reflects real needs and desires, making personal growth not just a goal but a shared adventure. In this space, every step forward is celebrated, every challenge is met with collective wisdom, and every success is a testament to the power of community-driven development.</p>
                {/* <p className="text-hightlight">Download the app now and unleash your potential with personalized growth plans.</p> */}
            </div>
        </>
    )
}
export default AboutUs