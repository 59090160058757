import * as react from "react";
import { BrowserRouter, Route, Routes, HashRouter } from "react-router-dom";
import WebLanding from "../web-landing/WebLanding";
import Header from "../../component/layouts/Header/Header";
import Footer from "../../component/layouts/footer";
import PrivacyPolicy from "../privacy-policy/PrivacyPolicy";
import Impressum from "../impressum/Impressum";
import TermCondition from "../term&condition/TermConditions";
import AboutUs from "../aboutUs/AboutUs";
import ScrollToTop from "../../component/ScrollToTop";


const Routing = () => {
    return (
        <HashRouter>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<WebLanding />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/term-condition" element={<TermCondition />} />
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/about-us" element={<AboutUs />} />
            </Routes>
            <Footer />
        </HashRouter>
    );
}
export default Routing;