import { Menu } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { images } from "../../../constants/images";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import ContactUs from "../../ContactUs";

const Header = () => {

    const handleScroll = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }

    const navigate = useNavigate()

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.wrapper-header');
        const scrollTop = window.scrollY;
        scrollTop >= 50 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const [open, setOpen] = useState(false);

    const handleHide = () => {
        setOpen(null);
    };

    const [show, setShow] = useState();

    const handleShow = () => {
        setShow(true)
    };
    
    const handleClose = () => setShow(false);

    const menuRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        function handleClickInside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setOpen(false);
            }
        }
        document.addEventListener('click', handleClickInside);
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            document.removeEventListener('click', handleClickInside);
        };
    }, []);

    const HandlePageChange = (path) => {
        navigate(path);
    };
    return (
        <>
            <div className="nav navbar wrapper-header">
                <div className="brand_logo">
                    <Link onClick={() => navigate("/")}>
                        <img src={images.logoImage} alt="logo" />
                    </Link>
                </div>
                <ul className="nav-list-item nav-desktop-srceen">
                    <li><Link className="nav-link" onClick={() => navigate("/about-us")}>About Us</Link></li>
                    <li><Link className="nav-link" onClick={handleScroll}>Legal and Compliance</Link></li>
                    <li><button type="button" className="common-btn" onClick={() => { handleShow() }}>Get in touch</button></li>
                </ul>
                <div className="nav-mobile-srceen" ref={menuRef}>
                    <button type="button" className="common-btn" onClick={() => { handleShow() }}>Get in touch</button>
                    <span ref={menuRef}>
                        <button className="btn-toggle" onClick={() => setOpen(!open)}><Menu /> </button>
                        <ul className={open ? 'nav-list-item show' : 'nav-list-item'} onClose={handleHide}>
                            <li><Link className="nav-link" onClick={() => { navigate('/about-us'); handleHide(); }}>About Us</Link></li>
                            <li><Link className="nav-link" onClick={() => { handleScroll(); handleHide(); }}>Legal and Compliance</Link></li>
                        </ul>
                    </span>
                </div>
            </div>
            {show &&
                <ContactUs
                    // id={id}
                    show={show}
                    setShow={setShow}
                    handleClose={handleClose}
                    handleShow={handleShow} />
            }
        </>
    );
}
export default Header;