import React from "react";
import { images } from "../../constants/images";
import { useNavigate } from "react-router-dom";
import { Link } from "@mui/material";
import ContactUs from "../ContactUs";
import { useState } from "react";

const Footer = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState();

    const handleShow = () => {
        setShow(true)
    };
    const handleClose = () => setShow(false);

    return (
        <>
            <div className="wrapper-fopter-sec" >
                <div className="row">
                    <div className="col-md-7">
                        <div className="wrap-footer-logo wrap-desktop-resercve-txt">
                            <Link className="footer-logo" onClick={() => navigate("/")}>
                                <img src={images.logoImage} alt="logo" />
                            </Link>
                            <p className="">© 2024 - All Rights Reserved.</p>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="footer-browser-item">
                                    <h6>Company</h6>
                                    <ul className="footer-nav-item">
                                        <li><Link onClick={() => navigate('/term-condition')}>Terms & Conditions</Link></li>
                                        <li><Link onClick={() => navigate('/privacy-policy')}>Privacy Policy</Link></li>
                                        <li><Link onClick={() => { handleShow() }}>Contact Us</Link></li>
                                        <li><Link onClick={() => navigate('/impressum')}>Impressum</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="footer-browser-item footer-browser-item2">
                                    <h6>Social</h6>
                                    <ul className="footer-nav-item">
                                        {/* <li><Link>Facebook</Link></li>
                                        <li><Link>YouTube</Link></li> */}
                                        <li><Link href="https://www.instagram.com/getbetter.app/?igsh=ZDdnNmxvd3p4d2Jo&utm_source=qr">Instagram</Link></li>
                                        <li><Link href="https://www.tiktok.com/@getbetterapps">TikTok</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrap-footer-logo wrap-mobile-resercve-txt">
                    <Link className="footer-logo"  onClick={() => navigate("/")}>
                        <img src={images.logoImage} alt="logo" />
                    </Link>
                    <p className="">© 2024 - All Rights Reserved.</p>
                </div>

            </div>
            {show &&
                <ContactUs
                    // id={id}
                    show={show}
                    setShow={setShow}
                    handleClose={handleClose}
                    handleShow={handleShow} />
            }
        </>
    );
}
export default Footer;